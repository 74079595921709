import React from "react";

import styles from "./jumbotron.module.scss";

export type Props = {
    backgroundImage: string;
    alt?: string;
};

const JumbotronImage: React.FC<Props> = ({ backgroundImage, alt }) => {
    return (
        <div className={styles.container}>
            <img src={backgroundImage} alt={alt} />
        </div>
    );
};

export default JumbotronImage;
