import React from "react";

import styles from "./jumbotron.module.scss";

export type Props = {
    backgroundVideo: string;
};

const JumbotronVideo: React.FC<Props> = ({ backgroundVideo }) => {
    return (
        <div className={styles.container}>
            <video preload="auto" loop playsInline autoPlay>
                <source src={backgroundVideo} type="video/mp4" />
            </video>
        </div>
    );
};

export default JumbotronVideo;
