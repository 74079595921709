import { BuilderConfig } from "@/builder/types";

import JumbotronVideo, { Props } from "./jumbotron-video";

export const JumbotronVideoBuilderConfig = {
    name: "JumbotronVideo",
    inputs: [
        {
            name: "backgroundVideo",
            type: "file",
            allowedFileTypes: ["mp4"],
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: JumbotronVideo,
    config: JumbotronVideoBuilderConfig,
};

export default builderConfig;
