import { BuilderConfig } from "@/builder/types";

import JumbotronImage, { Props } from "./jumbotron-image";

export const JumbotronImageBuilderConfig = {
    name: "JumbotronImage",
    inputs: [
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png", "gif"],
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: JumbotronImage,
    config: JumbotronImageBuilderConfig,
};

export default builderConfig;
